import _nuxt_components_plugin_mjs_KR1HBZs4kY from "C:/Users/otsuki/Desktop/JinStudio/CorporateSite/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "C:/Users/otsuki/Desktop/JinStudio/CorporateSite/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB from "C:/Users/otsuki/Desktop/JinStudio/CorporateSite/node_modules/nuxt/dist/pages/runtime/router.mjs";
import node_modules__64nuxtjs_device_dist_runtime_plugin_mjs_t2GMTTFnMT from "C:/Users/otsuki/Desktop/JinStudio/CorporateSite/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc from "C:/Users/otsuki/Desktop/JinStudio/CorporateSite/node_modules/nuxt/dist/app/plugins/payload.client.mjs";
import src_plugins_adobe_fonts_js_VrNdzEXfNt from "C:/Users/otsuki/Desktop/JinStudio/CorporateSite/src/plugins/adobe-fonts.js";
import src_plugins_img_js_kXgK2hzHos from "C:/Users/otsuki/Desktop/JinStudio/CorporateSite/src/plugins/img.js";
import src_plugins_mov_js_u8GY6u0pcY from "C:/Users/otsuki/Desktop/JinStudio/CorporateSite/src/plugins/mov.js";
export default [
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB,
  node_modules__64nuxtjs_device_dist_runtime_plugin_mjs_t2GMTTFnMT,
  node_modules_nuxt_dist_app_plugins_payload_client_mjs_5Om5dvb8Jc,
  src_plugins_adobe_fonts_js_VrNdzEXfNt,
  src_plugins_img_js_kXgK2hzHos,
  src_plugins_mov_js_u8GY6u0pcY
]