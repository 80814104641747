// GLOBAL 変数
const common = {
  global: {
    ver: '',
  },
}
export default {
  // props: common,
  methods: {
    /**
     * element の data-* を取得
     *
     * @returns {string}
     * @constructor
     */
    getVersion: function () {
      if (common.global.ver !== '') {
        return common.global.ver
      }

      // // MEMO 画像を変更してもキーが変わらない場合がある為、再取得がされない
      // const obj = {...el.dataset}
      // for (const [key] of Object.entries(obj)) {
      //   common.global.ver = key.toLowerCase().replace('v-', 'v')
      //   break
      // }

      // 30分毎にキーを取得
      common.global.ver = this.getBrowserCacheKey()

      return common.global.ver
    },
    getBrowserCacheKey: function () {
      const dt = new Date()
      return (
        dt.getFullYear() +
        ('0' + (dt.getMonth() + 1)).slice(-2) +
        ('0' + dt.getDate()).slice(-2) +
        ('0' + dt.getHours()).slice(-2) +
        ('0' + Math.floor(dt.getMinutes() / 30))
      )
    },
  },
}
