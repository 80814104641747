/* eslint-disable */
import { nextTick } from 'vue'
import { useNuxtApp } from '#app'
export default {
  scrollBehavior(to: any, from: any, savedPosition: any) {
    const nuxtApp = useNuxtApp()
    let position = savedPosition || void 0
    if (
      !position &&
      from &&
      to &&
      to.meta.scrollToTop !== false &&
      _isDifferentRoute(from, to)
    ) {
      position = { left: 0, top: 0 }
    }
    if (to.path === from.path) {
      if (from.hash && !to.hash) {
        return { left: 0, top: 0 }
      }
      if (to.hash) {
        let marginTop: number | false
        let toHash = to.hash
        marginTop = _getHashElementScrollMarginTop(toHash)
        if (marginTop !== false) {
          return { el: toHash, top: marginTop }
        }
      }
      if (to.hash.length === 0) {
        return { left: 0, top: 0 }
      }
    }
    const hookToWait = 'page:finish'
    return new Promise((resolve) => {
      nuxtApp.hooks.hookOnce(hookToWait, async () => {
        await nextTick()
        document.documentElement.classList.remove('loaded')
        if (to.hash) {
          const marginTop = _getHashElementScrollMarginTop(to.hash)
          if (false !== marginTop) {
            position = {
              el: to.hash,
              top: marginTop,
            }
          }
        }
        setTimeout(() => {
          resolve(position)
          setTimeout(() => {
            document.documentElement.classList.add('loaded')
          }, 10)
        }, 10)
      })
    })
  },
}
function _getHashElementScrollMarginTop(selector: any) {
  const elem = document.querySelector(selector)
  if (elem) {
    return parseFloat(getComputedStyle(elem).scrollMarginTop)
  }
  return false
}
function _isDifferentRoute(a: any, b: any) {
  const samePageComponent = a.matched[0] === b.matched[0]
  if (!samePageComponent) {
    return true
  }
  if (
    samePageComponent &&
    JSON.stringify(a.params) !== JSON.stringify(b.params)
  ) {
    return true
  }
  return false
}
