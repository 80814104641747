export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"仁Studioはゲーム(ソーシャル、コンシューマー、VR)、ビジネスサービス(メタバース、AR、VR、NFT)、映像制作(PV、アニメーション、遊技機等)の企画、開発、業務支援、運営を行っています。"},{"name":"format-detection","content":"telephone=no"},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"og:site_name","property":"og:site_name","content":"仁Studio株式会社"},{"hid":"og:url","property":"og:url","content":"https://jinstudio.co.jp"},{"hid":"og:image","property":"og:image","content":"https://jinstudio.co.jp/ogp_img.webp"},{"hid":"og:title","property":"og:title","content":"仁Studio株式会社"},{"hid":"og:description","property":"og:description","content":"仁Studioはゲーム(ソーシャル、コンシューマー、VR)、ビジネスサービス(メタバース、AR、VR、NFT)、映像制作(PV、アニメーション、遊技機等)の企画、開発、業務支援、運営を行っています。"},{"name":"twitter:card","content":"summary"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"}],"style":[],"script":[{"async":true,"defer":true,"crossorigin":"anonymous","src":"https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v17.0","nonce":"HH6JKbgJ"},{"async":true,"src":"https://maps.googleapis.com/maps/api/js?key=AIzaSyCeODOihcENjpiJd8qvxu3mQRhblRxUTLY&loading=async&libraries=places,marker"}],"noscript":[],"title":"仁Studio株式会社｜ゲーム、AR、VR、WEB、メタバース企画・制作・開発","htmlAttrs":{"lang":"jp"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"