import { defineNuxtPlugin } from 'nuxt/app'
import common from '../mixins/common'

const plugin = defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('mov', {
    beforeMount(el) {
      el.setAttribute(
        'src',
        el.getAttribute('src') + '?v=' + common.methods.getVersion(el)
      )
    },
  })
})
export default plugin
